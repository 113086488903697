.root{
    width: 100%;
    padding: 20px 40px;
    display: grid;
    row-gap: 36px;
}
.root-head{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    align-items: center;
    margin-bottom: 12px;
}
.root-image{
    width: 100%;
    height: auto;
}
.root-head h2{
    text-transform: uppercase;
    width: fit-content;
    margin: 0 auto;
}
.submit{
    background-color: #FFED00;
    padding: 24px 0;
    color: black;
    font-weight: bold;
    font-size: 26px;
    border-radius: 14px;
}
.sending{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(89, 89, 89, 0.4);
    z-index: 10;
}
.sending-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 200%;
    width: 0;
    background-color: #fff;
    box-shadow: 0 0 150px 20px #fff;
    animation: sending 2s ease infinite;
    transform: rotate(45deg);
}
@keyframes sending {
    from {
        transform: translateY(-100%) rotate(45deg);
    }
    to {
        transform: translateY(100%) rotate(45deg);
    }
}
@media (max-width: 575px) {
    .root{
        row-gap: 28px;
    }
}
@media (max-width: 405px) {
    .root{
        padding: 20px 10px;
    }
}