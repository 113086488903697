.emotions{
    display: grid;
    row-gap: 12px;
}
.emotions-body{
    display: grid;
    column-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    width: 75%;
    margin: auto;
}
.emotions-button{
    width: 100%;
    aspect-ratio: 1;
    background-size: 80% auto;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all .3s ease;
    border-radius: 35px;
}
.emotions-button_active{
    background-color: rgba(175, 175, 175, 0.5);
}
@media (hover:hover) {
    .emotions-button:hover{
        background-color: rgba(175, 175, 175, 0.3);
    }
}
@media (max-width: 575px) {
    .emotions-heading{
        font-size: 22px;
    }
}
@media (max-width: 500px) {
    .emotions-heading{
        font-size: 16px;
    }
}
@media (max-width: 430px) {
    .emotions-body{
        width: 90%;
    }
}