.admin{
    display: grid;
    width: 100%;
    row-gap: 24px;
    padding: 20px 10px;
}
.admin-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin-button{
    padding: 12px 24px;
    background-color: #FFED00;
    border-radius: 18px;
    color: black;
    font-weight: bold;
    font-size: 18px;
}
.modal{
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(89, 89, 89, 0.25);
    z-index: 10;
    display: grid;
    place-items: center;
    position: fixed;
    padding: 0 10px;
}
.modal-inner{
    width: 100%;
    padding: 20px 10px;
    background-color: #595959;
    border: 1px solid #AFAFAF;
    display: grid;
    row-gap: 14px;
}
.modal-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal-body{
    display: grid;
    row-gap: 8px;
}
.modal-close{
    width: 20px;
    aspect-ratio: 1;
    background-image: url("../images/close.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.modal-label{
    display: grid;
    row-gap: 4px;
}
.modal-input{
    width: 100%;
    padding: 12px;
    font-size: 18px;
    border: 1px solid #AFAFAF;
    background-color: rgba(175, 175, 175, 0.3);
    color: white;
}
.modal-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1; /* Firefox */
}
.modal-input::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgba(255, 255, 255, 0.5);
}
.modal-btn{
    background-color: #FFED00;
    color: black;
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
    border-radius: 8px;
}
.admin-list{
    display: grid;
    row-gap: 14px;
}
.club-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.club-right{
    display: flex;
    align-items: center;
    column-gap: 24px;
}
.club-edit{
    width: 24px;
    aspect-ratio: 1;
    background-image: url("../images/edit.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.club-copy{
    width: 24px;
    aspect-ratio: 1;
    background-image: url("../images/copy.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.club-delete{
    width: 24px;
    aspect-ratio: 1;
    background-image: url("../images/trash.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}