.comment{
    display: grid;
    row-gap: 14px;
}
.comment-text{
    resize: none;
    width: 100%;
    border: 2px solid #AFAFAF;
    padding: 14px;
    border-radius: 24px;
    height: 158px;
    font-size: 24px;
}
@media (max-width: 575px) {
    .comment-head{
        font-size: 24px;
    }
}
@media (max-width: 500px) {
    .comment-head{
        font-size: 20px;
    }
}
@media (max-width: 405px) {
    .comment-head{
        font-size: 18px;
    }
}