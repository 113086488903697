.phone{
    display: grid;
    row-gap: 12px;
}
.input-wrapper{
    position: relative;
    width: 100%;
    height: 60px;
}
.input{
    font-size: 18px !important;
    padding: 0 14px;
    width: 100%;
    height: 60px !important;
    border: 1px solid #595959 !important;
    background-color: #595959 !important;
    color: white !important;
}
.react-international-phone-country-selector-dropdown{
    background-color: #333333!important;
}
.react-international-phone-country-selector-dropdown__list-item--focused{
    background-color: #595959!important;
}
.react-international-phone-country-selector-dropdown__list-item--selected{
    background-color: #595959!important;
}
.react-international-phone-country-selector-dropdown__list-item:hover{
    background-color: #595959!important;
}
.react-international-phone-input--disabled{
    background-color: rgba(89, 89, 89, 0.5) !important;
}
.phone-label{
    display: flex;
    align-items: center;
    column-gap: 8px;
}
@media (max-width: 575px) {
    .phone-heading{
        font-size: 22px;
    }
}
@media (max-width: 500px) {
    .phone-heading{
        font-size: 16px;
    }
}