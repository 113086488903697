* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  border: 0;
  background-color: transparent;
  outline: none;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
}
.App{
  width: 100%;
}
body{
  background-color: #333333;
}
.span-red{
  color: red;
}