.not-found{
    width: 100%;
    height: 100vh;
    display: grid;
    justify-content: center;
    text-align: center;
}
.not-found_content{
    width: 100%;
    height: fit-content;
    align-self: center;
    justify-content: center;
}
