.thanks{
    width: 100%;
    height: 100vh;
    display: grid;
    text-align: center;
    justify-content: center;
}
.thanks-content{
    width: 75%;
    margin: auto;
}