.label-attach{
    width: 100%;
    display: block;
}
.input-attach{
    display: none;
}
@media (max-width: 575px) {
    .file-heading{
        font-size: 22px;
    }
}
@media (max-width: 500px) {
    .file-heading{
        font-size: 16px;
    }
}