.auth{
    display: grid;
    width: 100%;
    height: 100vh;
    padding: 20px 10px;
    place-items: center;
}
.auth-content{
    width: 100%;
    display: grid;
    row-gap: 24px;
}
.auth-input{
    width: 100%;
    padding: 16px;
    font-size: 18px;
    color: white;
    background-color: #595959;
    border-radius: 32px;
    border: 2px solid #AFAFAF;
}
.auth-button{
    width: 100%;
    padding: 24px;
    background-color: #FFED00;
    border-radius: 32px;
    color: black;
    font-size: 22px;
    font-weight: bold;
}